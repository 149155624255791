import http from '../http'

export const getParentOrder = (id) => {
    return http({
        url: '/web/build/order/getBuildOrderByExId',
        method: 'get',
        params: {
            id
        }
    })
}

export const createBuildOrder = (data) => {
    return http({
        url: '/web/build/order/createBuildOrder',
        method: 'post',
        data
    })
}

export const getBuildOrderList = (id) => {
        return http({
            url: '/web/build/order/getBuildOrderByExId',
            method: 'get',
            params: {
                id
            }
        })
    }
    //修改父订单
export const editBuildOrder = (data) => {
        return http({
            url: '/web/build/order/updateBuildOrder',
            method: 'post',
            data
        })
    }
    //创建
export const createBuildHomeOrder = (data) => {
    return http({
        url: '/web/build/home/createBuildOrder',
        method: 'post',
        data
    })
}

//修改订单
export const upDateBuildHomeOrder = (data) => {
    return http({
        url: '/web/build/home/updateBuildOrder',
        method: 'post',
        data
    })
}

//查看父级订单状态
export const getParentOrderSts = (id) => {
    return http({
        url: '/web/build/order/getById',
        method: 'get',
        params: {
            id
        }
    })
}

//数据回显用
export const getParentOrderData = (id) => {
    return http({
        url: '/web/build/home/getOrder',
        method: 'get',
        params: {
            id
        }
    })
}

//根据父级订单id查看搭建商文件审核子订单

export const viewFileOrder = (id) => {
    return http({
        url: '/web/build/file/getBuildFile',
        method: 'get',
        params: {
            id
        }
    })
}

//创建
export const createFileOrder = (data) => {
    return http({
        url: '/web/build/file/createBuildFile',
        method: 'post',
        data
    })
}

//修改
export const editFileOrder = (data) => {
    return http({
        url: '/web/build/file/updateBuildFile',
        method: 'post',
        data
    })
}

//提交搭建商父级订单,回传父级订单id

export const commitFinalOrder = (id) => {
    return http({
        url: '/web/build/order/commitBuildParentOrder',
        method: 'get',
        params: {
            id
        }
    })
}


//留言增加

export const messageAdd = (data) => {
    return http({
        url: '/tbMessage/add',
        method: 'post',
        data
    })
}

//酒店表查询

export const hotelSearch = () => {
    return http({
        url: '/tbHotel/list',
        method: 'get',
    })
}

//查询搭建商个人中心列表

export const getOrderList = (orderState) => {
    return http({
        url: '/web/build/order/getOrderList',
        method: 'get',
        params: {
            orderState
        }
    })
}

//查询付款单信息
export const getPayment = (orderId) => {
    return http({
        url: `/web/build/order/payment/${orderId}`,
        method: 'get'
    })
}

//搭建商上传支付凭证

export const payZheng = (data) => {
    return http({
        url: '/web/build/order/updateByPayImg',
        method: 'post',
        data
    })
}

//搭建商申请退押金

export const tuiYa = (data) => {
    return http({
        url: '/web/build/order/updateByDeImg',
        method: 'post',
        data
    })
}

//搭建商申请开票

export const kaipiao = (id) => {
    return http({
        url: '/web/build/order/updateByk',
        method: 'get',
        params: {
            id
        }
    })
}

//(新) 获取文件类型 /web/fileType/list/{buildId}

export const getFileType = (buildId) => {
    return http({
        url: `/web/fileType/list/${buildId}`,
        method: 'get'
    })
}