<template>
  <div>
    <!-- 轮播图 -->
    <div class="swiper">
      <el-carousel height="700px">
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <img class="swiper-img" :src="$fimg(item.bannerImg)" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="exhibition">
      <div class="title">
        <span class="text"><span class="blue">展会</span>列表</span>
        <div class="line">
          <div class="left"></div>
          <span>Exhibition list</span>
          <div class="right"></div>
        </div>
      </div>
      <div class="list">
        <div
          class="item-box"
          v-for="i in exhibitionList"
          :key="i.id"
          @click="toNext(i.id)"
        >
          <div class="item">
            <img class="img" :src="$fimg(i.imgUrl)" alt="" />
            <span class="name">{{ i.exName }}</span>
            <span class="date">{{ i.exTime }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-box">
      <div class="bottom">
        <div class="left">
          <div class="item">
            <span class="span">增值业务</span>
            <ul class="ul">
              <li @click="hotelreservation">酒店预订</li>
              <li @click="dialog1 = true">接送服务</li>
              <li @click="dialog = true">联系方式</li>
              <li @click="dialog2 = true">线上留言</li>
            </ul>
          </div>
          <div class="item">
            <span class="span">公司业务</span>
            <ul class="ul">
              <li>展台搭建</li>
              <li>主场服务</li>
              <li>活动策划</li>
              <li>现场执行</li>
              <li>会务接待</li>
            </ul>
          </div>
        </div>
        <div class="center">
          <!-- <div class="item">
            <img src="@/assets/img/erweima1.png" alt="" />
          </div>
          <div class="item">
            <img src="@/assets/img/erweima2.png" alt="" />
          </div> -->
        </div>
        <div class="right">
          <span class="span">上海奥视展览展示服务有限公司</span>
          <span class="span">
            <span class="text">展厅设计</span>
            <span class="text">展览设计</span>
            <span class="text">展示设计</span>
            <span class="text">展会搭建</span>
            <span class="text">展台搭建</span>
          </span>
          <span class="span">全国资讯热线：400 068 9655/021-51096558</span>
          <span class="span">设计专线：400 068 9655/021-51096558</span>
          <span class="span">联系邮箱：shours888@126.com</span>
          <!-- <span class="span">官网网站：http://www.fuwen.com/</span> -->
          <span class="span"
            >总部地址：上海市浦东新区金高路2216号金桥谷创意园6栋412室
          </span>
        </div>
      </div>
      <div class="dn">
        <span>版权所有© 上海奥视展览展示服务有限公司</span>
        <span>沪ICP备20023615号</span>
      </div>
    </div>
    <el-dialog title="接送服务" :visible.sync="dialog1" width="50%">
      <div class="message">
        <div class="item">
          <span class="span">接送人姓名：</span
          ><input class="input" type="text" v-model="name" />
        </div>
        <div class="item">
          <span class="span">联系电话：</span
          ><input class="input" type="text" v-model="phone" />
        </div>
        <div class="item">
          <span class="span">接送地点：</span>
          <span class="span">接地点：</span>
          <input class="input" type="text" v-model="start" />
          <span class="span" style="margin-left: 20px">送地点：</span>
          <input class="input" type="text" v-model="end" />
        </div>
        <!-- <div class="item">
          <span class="span">接送时间：</span
          ><input class="input" type="text" v-model="startTime"/>
        </div> -->
        <div class="item">
          <span class="span">接送留言：</span
          ><input class="input" type="text" v-model="jieMessage" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog1 = false">取 消</el-button>
        <el-button type="primary" @click="server">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="线上留言" :visible.sync="dialog2" width="50%">
      <div class="message">
        <div class="item">
          <span class="span">姓名：</span
          ><input class="input" type="text" v-model="name" />
        </div>
        <div class="item">
          <span class="span">联系电话：</span
          ><input class="input" type="text" v-model="phone" />
        </div>
        <div class="item">
          <span class="span">留言内容：</span
          ><input class="input" type="text" v-model="content" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog2 = false">取 消</el-button>
        <el-button type="primary" @click="messageAddFun">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="联系我们" :visible.sync="dialog" width="50%">
      <div class="dialog">400 068 9655/021-51096558</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="dialog = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getExhibition, exhibitionAuth } from "../utils/api/index";
import { messageAdd } from "../utils/api/shop";
import { getUserInfo, getBanner } from "@/utils/api/user";

export default {
  data() {
    return {
      dialog: false,
      dialog1: false,
      dialog2: false,
      exhibitionList: [],
      userInfo: {},
      content: "",
      name: "",
      phone: "",
      start: "",
      end: "",
      startTime: "",
      jieMessage: "",
      list: [],
    };
  },
  mounted() {
    // this.userInfo = JSON.parse(localStorage.getItem('userInfos'))
    this.getUserInfoFun();
    this.getExhibitionFun();
    this.getBannerFun();
  },
  methods: {
    getBannerFun() {
      getBanner().then((res) => {
        this.list = res.data.rows;
      });
    },
    getUserInfoFun() {
      getUserInfo().then((res) => {
        this.userInfo = res.data;
      });
    },
    //接送服务
    server() {
      let params = {
        name: this.name,
        phone: this.phone,
        startAddr: this.start,
        endAddr: this.end,
        content: this.content,
      };
      messageAdd(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("提交成功");
          this.dialog1 = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //线上留言
    messageAddFun() {
      if (!this.name) {
        this.$message.error("请输入姓名");
        return;
      }
      if (!this.phone) {
        this.$message.error("请输入联系电话");
        return;
      }
      if (!this.content) {
        this.$message.error("请输入留言内容");
        return;
      }
      let params = {
        msgType: 3,
        content: this.content,
        name: this.name,
        phone: this.phone,
      };
      messageAdd(params).then((res) => {
        this.dialog2 = false;
        if (res.code == 200) {
          this.$message.success("提交成功");
        }
      });
    },
    hotelreservation() {
      this.$router.push("index/hotelreservation");
    },
    getExhibitionFun() {
      getExhibition().then((res) => {
        this.exhibitionList = res.data;
      });
    },
    toNext(id) {
      if (this.userInfo.isType == 1) {
        localStorage.setItem("exhibitionId", id);
        this.$router.push("/index/setupnewspaper");
      }
      if (this.userInfo.isType == 2) {
        exhibitionAuth(id).then((res) => {
          if (res.code == 200) {
            localStorage.setItem("exhibitionId", id);
            this.$router.push("/index/exhibitor");
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.message {
  padding: 20px;
  padding-bottom: 0;
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .span {
      width: 100px;
    }
    .input {
      flex: 1;
      // width: 100%;
      height: 40px;
    }
  }
}
.swiper {
  background-color: #333;
  .swiper-img {
    width: 100%;
    height: 100%;
  }
}
.exhibition {
  padding: 50px 0;
  background-color: #e9e9e9;
  .title {
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    .text {
      font-size: 42px;
      .blue {
        color: #66b1ff;
      }
    }
    .line {
      display: flex;
      align-items: center;
      color: #999;
      .left {
        width: 100px;
        height: 1px;
        background-color: #999;
        margin-right: 30px;
      }
      .right {
        width: 100px;
        height: 1px;
        background-color: #999;
        margin-left: 30px;
      }
    }
  }
  .list {
    width: 98vw;
    margin: 0 auto;
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    .item-box {
      flex: 0 1 33.3%;
      .item {
        width: 94%;
        height: 90%;
        margin: 20px auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        justify-content: space-between;
        overflow: hidden;
        margin-bottom: 10px;
        padding-bottom: 8px;
        .img {
          height: 280px;
          width: 100%;
        }
        .name {
          font-size: 16px;
          font-weight: 700;
          padding: 8px;
          color: #7f7f7f;
        }
        .date {
          font-size: 12px;
          color: #999;
        }
      }
    }
  }
}
.bottom-box {
  background-color: #222222;
  .bottom {
    margin: 50px auto;
    margin-bottom: 0;
    width: 1200px;
    display: flex;
    .left {
      width: 432px;
      display: flex;
      justify-content: space-around;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        padding: 20px 0;
        .span {
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 10px;
        }
        .ul {
          display: flex;
          flex-direction: column;
          align-items: left;
          // 取消列表默认样式
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            margin: 5px 0;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
    .center {
      margin: 0 50px;
      width: 300px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      .item {
        height: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        color: #fff;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: left;
      color: #909090;
      justify-content: center;
      font-size: 14px;
      .span {
        margin-bottom: 4px;
        .text {
          margin-right: 4px;
        }
      }
    }
  }
  .dn {
    display: flex;
    justify-content: space-between;
    padding: 10px 450px;
    color: #fff;
    border-top: 1px solid #363636;
  }
}

::v-deep .el-carousel__button {
  width: 16px;
  height: 16px;
  background-color: #9aa0a8;
  border-radius: 8px;
}
::v-deep .is-active .el-carousel__button {
  background-color: #f75352;
}
</style>
